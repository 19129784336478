.project {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 20px;
  background: #ffffff;
  border: 0;
  border-radius: 4px;
  color: #000000;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}
.project:hover {
  background: #edf2f7;
}
.project__icon {
  margin-right: 15px;
  padding: 4px;
}
.project__icon-circle {
  width: 16px;
  height: 16px;
  background: #cccccc;
  border-radius: 50%;
}
