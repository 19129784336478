.plus-button {
  display: flex;
  align-items: center;
  padding: 8px 20px;
  background: #ffffff;
  border: 0;
  color: #000000;
  font-family: "Lato";
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}
.plus-button__icon {
  margin-right: 15px;
}
