@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap);
body {
  font-family: 'Lato', sans-serif;
}

/* CSS RESET */
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
.header {
  background: #f2f3f5;
  padding: 20px 0;
}
.header__logo {
  height: 22px;
}

.create-form {
  width: 100%;
}
.create-form__input {
  display: block;
  width: 100%;
  margin-bottom: 15px;
  padding: 4px 8px;
  background: #fffdfd;
  border: 1px solid #cccccc;
  border-radius: 4px;
  font-family: "Lato";
  font-size: 16px;
  line-height: 24px;
}
.create-form__buttons {
  position: relative;
  display: flex;
}
.create-form__add-button {
  padding: 5px 15px;
  margin-right: 15px;
  background: #2d3748;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #ffffff;
  font-family: "Lato";
  font-size: 16px;
  line-height: 24px;
}
.create-form__cancel-button {
  padding: 5px 15px;
  margin-right: 15px;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  color: #000000;
  font-family: "Lato";
  font-size: 16px;
  line-height: 24px;
}

.update-form {
  width: 100%;
}
.update-form__input {
  display: block;
  width: 100%;
  margin-bottom: 15px;
  padding: 4px 8px;
  background: #fffdfd;
  border: 1px solid #cccccc;
  border-radius: 4px;
  font-family: "Lato";
  font-size: 16px;
  line-height: 24px;
}
.update-form__buttons {
  position: relative;
  display: flex;
}
.update-form__update-button {
  padding: 5px 15px;
  margin-right: 15px;
  background: #2d3748;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #ffffff;
  font-family: "Lato";
  font-size: 16px;
  line-height: 24px;
}
.update-form__cancel-button {
  padding: 5px 15px;
  margin-right: 15px;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  color: #000000;
  font-family: "Lato";
  font-size: 16px;
  line-height: 24px;
}
.update-form__delete-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 15px 3px;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  font-family: "Lato";
}

.task {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 15px;
  border-bottom: 1px solid #aaaaaa;
}
.task__title {
  color: #2d3748;
  font-size: 17px;
  line-height: 24px;
}
.task.task--completed {
  opacity: 0.5;
}
.task.task--completed .task__title {
  text-decoration: line-through;
}
.task__checkbox {
  width: 20px;
  height: 20px;
  margin-right: 12px;
}

.action-button {
  align-self: flex-end;
  margin-bottom: 15px;
  padding: 8px 15px;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  font-family: "Lato";
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
}

.icon-button {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 20px;
  background: #ffffff;
  border: 0;
  border-radius: 4px;
  color: #000000;
  font-family: "Lato";
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}
.icon-button:hover {
  background: #edf2f7;
}
.icon-button__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  margin-right: 15px;
}

.plus-button {
  display: flex;
  align-items: center;
  padding: 8px 20px;
  background: #ffffff;
  border: 0;
  color: #000000;
  font-family: "Lato";
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}
.plus-button__icon {
  margin-right: 15px;
}

.project {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 20px;
  background: #ffffff;
  border: 0;
  border-radius: 4px;
  color: #000000;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}
.project:hover {
  background: #edf2f7;
}
.project__icon {
  margin-right: 15px;
  padding: 4px;
}
.project__icon-circle {
  width: 16px;
  height: 16px;
  background: #cccccc;
  border-radius: 50%;
}

/* reset */
* {
  box-sizing: border-box;
}
html {
  font-family: "Lato", Arial, Helvetica, sans-serif;
  font-weight: 400;
}
.container {
  width: 100%;
  max-width: 960px;
  margin: auto;
  padding: 0 15px;
}
.app {
  width: 100%;
}
.app__container {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  padding-bottom: 30px;
}
@media (min-width: 960px) {
  .app__container {
    flex-direction: row;
  }
}
.app__icon {
  margin-right: 8px;
}
/* app__aside */
.app__aside {
  width: 100%;
  padding: 15px 0;
}
@media (min-width: 960px) {
  .app__aside {
    width: 25%;
    margin-right: 30px;
  }
}
/* app__main */
.app__main {
  width: 100%;
}
@media (min-width: 960px) {
  .app__main {
    width: 75%;
  }
}
.main__title {
  margin-bottom: 15px;
  color: #1f2124;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
}
.main__tasks {
  margin-bottom: 15px;
}
.main__form-container {
  margin-bottom: 45px;
}
.main__completed-tasks {
  display: flex;
  flex-direction: column;
}

