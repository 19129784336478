/* reset */
* {
  box-sizing: border-box;
}
html {
  font-family: "Lato", Arial, Helvetica, sans-serif;
  font-weight: 400;
}
.container {
  width: 100%;
  max-width: 960px;
  margin: auto;
  padding: 0 15px;
}
.app {
  width: 100%;
}
.app__container {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  padding-bottom: 30px;
}
@media (min-width: 960px) {
  .app__container {
    flex-direction: row;
  }
}
.app__icon {
  margin-right: 8px;
}
/* app__aside */
.app__aside {
  width: 100%;
  padding: 15px 0;
}
@media (min-width: 960px) {
  .app__aside {
    width: 25%;
    margin-right: 30px;
  }
}
/* app__main */
.app__main {
  width: 100%;
}
@media (min-width: 960px) {
  .app__main {
    width: 75%;
  }
}
.main__title {
  margin-bottom: 15px;
  color: #1f2124;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
}
.main__tasks {
  margin-bottom: 15px;
}
.main__form-container {
  margin-bottom: 45px;
}
.main__completed-tasks {
  display: flex;
  flex-direction: column;
}
