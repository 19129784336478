.task {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 15px;
  border-bottom: 1px solid #aaaaaa;
}
.task__title {
  color: #2d3748;
  font-size: 17px;
  line-height: 24px;
}
.task.task--completed {
  opacity: 0.5;
}
.task.task--completed .task__title {
  text-decoration: line-through;
}
.task__checkbox {
  width: 20px;
  height: 20px;
  margin-right: 12px;
}
